import React, { useEffect, useRef, useState } from "react";
import "./comPosts.scss";
import { FaSquarePollHorizontal } from "react-icons/fa6";
import { BsThreeDotsVertical } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { FaShare } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Comments from "./Comments";
import Like from "./Like";
import { MdAddToPhotos } from "react-icons/md";
import { ApiBaseUrl } from "../../../BaseUrl";
import axios from "axios";

const ComPosts = ({ GetAllPosts, allPosts }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [caption, setCaption] = useState("");
  const [categoryError, setCategoryError] = React.useState("");
  const [captionError, setCaptionError] = React.useState("");
  const [answer, setAnswer] = useState([]);
  const loggedIn = localStorage.getItem("PARENT_ID");
  const [thirdOptionDisplay, setThirdOptionDisplay] = useState({
    display: "none",
  });
  const [fourthOptionDisplay, setFourthOptionDisplay] = useState({
    display: "none",
  });
  const [addMoreOptionButtonDisplay, setAddMoreOptionButtonDisplay] = useState({
    display: "flex",
  });
  const [removeThirdOptionDisplay, setRemoveThirdOptionDisplay] = useState({
    display: "flex",
  });
  const [removeFourthOptionDisplay, setRemoveFourthOptionDisplay] = useState({
    display: "flex",
  });

  // Images Drag and Drop and Preview Section Code::
  const [dDImages, setDDImages] = useState([]);
  const [actualImage, setActualImage] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef(null);

  const SelectFiles = () => {
    fileInputRef.current.click();
  };

  const OnFileSelect = (event) => {
    const files = event.target.files;
    setActualImage(files);
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!dDImages.some((e) => e.name === files[i].name)) {
        setDDImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };
  const RemoveImage = (index) => {
    setDDImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const OnDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const OnDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const OnDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!dDImages.some((e) => e.name === files[i].name)) {
        setDDImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };

  // Videos Drag and Drop and Preview Section Code:::
  const [dDVideos, setDDVideos] = useState([]);
  const [actualVid, setActualVid] = useState([]);
  const [isVidDragging, setIsVidDragging] = useState(false);
  const videoInputRef = useRef(null);

  const SelectVidFiles = () => {
    videoInputRef.current.click();
  };

  const OnVidFileSelect = (event) => {
    const files = event.target.files;
    // setActualVid(files);
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "video") continue;
      if (!dDVideos.some((e) => e.name === files[i].name)) {
        setDDVideos((prevVideos) => [
          ...prevVideos,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };

  const RemoveVideo = (index) => {
    setDDVideos((prevVideos) => prevVideos.filter((_, i) => i !== index));
  };

  const OnVidDragOver = (event) => {
    event.preventDefault();
    setIsVidDragging(true);
    event.dataTransfer.dropEffect = "copy";
  };

  const OnVidDragLeave = (event) => {
    event.preventDefault();
    setIsVidDragging(false);
  };

  const OnVidDrop = (event) => {
    event.preventDefault();
    setIsVidDragging(false);
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "video") continue;
      if (!dDVideos.some((e) => e.name === files[i].name)) {
        setDDVideos((prevVideos) => [
          ...prevVideos,
          {
            name: files[i].name,
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleCaptionChange = (event) => {
    setCaption(event.target.value);
  };

  const GetCategories = async () => {
    try {
      const response = await axios.get(`${ApiBaseUrl}/categories`);
      setCategories(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetCategories();
  }, []);

  // Handling Add Post Functionality
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const [videos, setVideos] = useState([]);
  const [videoPreviews, setVideoPreviews] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [modalVideo, setModalVideo] = useState(null);

  const handleImageChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const combinedImages = [...selectedImages, ...newFiles];

    if (combinedImages.length > 4) {
      toast.warning("You can only select up to 4 images.", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    const validImages = combinedImages.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );
    if (validImages.length > 0) {
      const imagePreviewsArray = validImages.map((image) =>
        URL.createObjectURL(image)
      );
      setSelectedImages(validImages);
      setImages(validImages);
      setImagePreviews(imagePreviewsArray);
    } else {
      toast.error("Kindly select image files of size up to 5MB.", {
        position: "top-center",
        autoClose: 2000,
      });
      event.target.value = null;
    }
  };

  const handleVideoChange = (event) => {
    const newFiles = Array.from(event.target.files);
    const combinedImages = [...selectedVideos, ...newFiles];
    if (combinedImages.length > 4) {
      toast.warning("You can only select up to 4 videos.", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    const validImages = combinedImages.filter(
      (file) => file.size <= 5 * 1024 * 1024
    );
    if (validImages.length > 0) {
      const imagePreviewsArray = validImages.map((image) =>
        URL.createObjectURL(image)
      );
      setSelectedVideos(validImages);
      setVideos(validImages);
      setVideoPreviews(imagePreviewsArray);
    } else {
      toast.error("Kindly select videos of size up to 5MB.", {
        position: "top-center",
        autoClose: 2000,
      });
      event.target.value = null;
    }
  };

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalImage(null);
  };

  const openVideoModal = (image) => {
    setModalVideo(image);
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
    setModalVideo(null);
  };

  const handleDeleteImage = (index) => {
    // Remove the selected image by index
    const updatedPreviews = imagePreviews.filter((_, i) => i !== index);
    const updatedImages = selectedImages.filter((_, i) => i !== index);

    // Update state
    setImagePreviews(updatedPreviews);
    setSelectedImages(updatedImages);
  };
  const handleDeleteVideo = (index) => {
    // Remove the selected image by index
    const updatedPreviews = videoPreviews.filter((_, i) => i !== index);
    const updatedImages = selectedVideos.filter((_, i) => i !== index);

    // Update state
    setVideoPreviews(updatedPreviews);
    setSelectedVideos(updatedImages);
  };

  const AddPost = async () => {
    let validationError = false;
  
    if (!selectedCategory) {
      setCategoryError("Please select a category.");
      validationError = true;
    } else {
      setCategoryError(""); // Clear error if valid
    }
  
    if (!caption.trim()) {
      setCaptionError("Please enter a caption.");
      validationError = true;
    } else {
      setCaptionError(""); // Clear error if valid
    }
  
    if (validationError) return;
  
    try {
      const formData = new FormData();
      formData.append("client", loggedIn);
      formData.append("caption_description", caption);
      formData.append("category", selectedCategory);
  
      const imageFieldNames = [
        "image_one",
        "image_two",
        "image_three",
        "image_four",
      ];
      images.forEach((image, index) => {
        if (index < imageFieldNames.length) {
          formData.append(imageFieldNames[index], image);
        }
      });
  
      const videoFieldNames = [
        "video_one",
        "video_two",
        "video_three",
        "video_four",
      ];
      videos.forEach((video, index) => {
        if (index < videoFieldNames.length) {
          formData.append(videoFieldNames[index], video);
        }
      });
      const response = await axios.post(
        `${ApiBaseUrl}/communitypost`,
        formData
      );
      GetAllPosts();
      setCaption("");
      setSelectedCategory(null); // Reset category selection
      toast.success("Post added successfully.", { autoClose: 1500 });
      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Error uploading post:", error);
    }
  };
  
  const [options, setOptions] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  });

  const handleChange = (e, option) => {
    const { value } = e.target;
    setOptions((prevOptions) => ({
      ...prevOptions,
      [option]: value,
    }));
  };
  
  const CreatePoll = async () => {
  try {
    // Check if all required fields are filled
    if (!selectedCategory) {
      toast.error("Please select a category.", {
        autoClose: 1500,
      });
      return;
    }
    if (!caption.trim()) {
      toast.error("Please enter a poll question.", {
        autoClose: 1500,
      });
      return;
    }
    if (!options.option1.trim() || !options.option2.trim()) {
      toast.error("Option one and option two are required.", {
        autoClose: 1500,
      });
      return;
    }

    // Additional options are optional, but if they exist, trim validation can be applied
    if (options.option3 && !options.option3.trim()) {
      toast.error("Option three cannot be empty.", {
        autoClose: 1500,
      });
      return;
    }
    if (options.option4 && !options.option4.trim()) {
      toast.error("Option four cannot be empty.", {
        autoClose: 1500,
      });
      return;
    }

    // Prepare form data
    const formData = new FormData();
    formData.append("client", loggedIn);
    formData.append("category", selectedCategory);
    formData.append("poll_question", caption.trim());
    formData.append("option_one", options.option1.trim());
    formData.append("option_two", options.option2.trim());
    if (options.option3) formData.append("option_three", options.option3.trim());
    if (options.option4) formData.append("option_four", options.option4.trim());

    // API call
    const response = await axios.post(`${ApiBaseUrl}/communitypost`, formData);

    // Clear inputs and refresh data
    GetAllPosts();
    setCaption("");
    setOptions({ option1: "", option2: "", option3: "", option4: "" });
    setSelectedCategory(null);
    toast.success("Created poll successfully.", {
      autoClose: 1500,
    });
    console.log("Upload successful:", response.data);
  } catch (error) {
    toast.error("Failed to create poll. Please try again.", {
      autoClose: 1500,
    });
    console.error("Error uploading poll:", error);
  }
};

  const [onlyCaption, setOnlyCaption] = useState("");

  // Function to handle input change
  const handleInputChange = (event) => {
    setOnlyCaption(event.target.value);
  };

  const PostOnlyCaption = async () => {
    try {
      const formData = new FormData();
      formData.append(`client`, loggedIn);
      formData.append(`caption_description`, onlyCaption);
      const response = await axios.post(
        `${ApiBaseUrl}/communitypost`,
        formData
      );
      // GetAllPosts();
      toast.success("Post added successfully.", {
        autoClose: 1500,
      });
      console.log("Upload successful:", response.data);
    } catch (error) {
      console.error("Error uploading images:", error);
    }
  };

  const HandleAnswering = (postId) => {
    if (answer.includes(postId)) {
      // If comments are already open, close them
      setAnswer(answer.filter((id) => id !== postId));
    } else {
      // If comments are not open yet, open them
      setAnswer([...answer, postId]);
    }
  };

  const OnVote = async (postId, index) => {
    if (loggedIn) {
      HandleAnswering(postId);
      try {
        const option = {
          option: index,
        };
        const response = await axios.post(
          `${ApiBaseUrl}/poll/${loggedIn}/${postId}/vote`,
          option
        );
        GetAllPosts();
        // toast.success("Poll answered successfully.", {
        //   autoClose: 1500,
        // });
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    } else {
      navigate("/login");
    }
  };

  const AddMoreOption = () => {
    if (
      thirdOptionDisplay.display === "none" &&
      fourthOptionDisplay.display === "none"
    ) {
      setThirdOptionDisplay({ display: "flex" });
      setFourthOptionDisplay({ display: "none" });
    } else if (
      thirdOptionDisplay.display === "flex" &&
      fourthOptionDisplay.display === "none"
    ) {
      setFourthOptionDisplay({ display: "flex" });
      setThirdOptionDisplay({ display: "flex" });
      setAddMoreOptionButtonDisplay({ display: "none" });
      setRemoveThirdOptionDisplay({ display: "none" });
      setRemoveFourthOptionDisplay({ display: "flex" });
    }
  };

  const RemoveThirdPollOption = () => {
    setFourthOptionDisplay({ display: "none" });
    setThirdOptionDisplay({ display: "none" });
    setAddMoreOptionButtonDisplay({ display: "flex" });
    setRemoveThirdOptionDisplay({ display: "flex" });
    setRemoveFourthOptionDisplay({ display: "flex" });
    setOptions({
      ...options,
      option3: "",
    });
  };

  const RemoveFourthPollOption = () => {
    setFourthOptionDisplay({ display: "none" });
    setThirdOptionDisplay({ display: "flex" });
    setAddMoreOptionButtonDisplay({ display: "flex" });
    setRemoveThirdOptionDisplay({ display: "flex" });
    setRemoveFourthOptionDisplay({ display: "flex" });
    setOptions({
      ...options,
      option4: "",
    });
  };

  const [moreCaption, setMoreCaption] = useState(false);
  const [seeMoreState, setSeeMoreState] = useState("...more");

  const SeeMoreCaption = () => {
    if (moreCaption === false) {
      setMoreCaption(true);
      setSeeMoreState("...less");
    } else {
      setMoreCaption(false);
      setSeeMoreState("...more");
    }
  };

  const resetModal = () => {
    imagePreviews.forEach((preview) => URL.revokeObjectURL(preview));
    setImagePreviews([]);
    setCaption('');
    setSelectedCategory('');
    setCategoryError('');
    setCaptionError('');
  };

  return (
    <>
      <section className="community_posts_wrapper">
        <div className="community_posts">
          <div className="add_post_wrapper">
            <div
              className="only_caption_input_container"
              style={{ display: "none" }}
            >
              <input
                type="text"
                placeholder="Type your caption"
                onChange={handleInputChange}
                value={onlyCaption}
              />

              {onlyCaption.trim() !== "" && (
                <button className="post_only_caption" onClick={PostOnlyCaption}>
                  Post
                </button>
              )}
            </div>
            <div className="add_post_buttons">
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={resetModal}
              >
                <MdAddToPhotos className="btn_icons" />
                <span>Create Post</span>
              </button>
              <button
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
              >
                <FaSquarePollHorizontal className="btn_icons" />
                <span>Create Poll</span>
              </button>
            </div>
          </div>
          <div className="all_posts_wrapper">
            {allPosts.map((item, index) => {
              const allNull = !(
                item.image_one ||
                item.image_two ||
                item.image_three ||
                item.image_four ||
                item.video_one ||
                item.video_two ||
                item.video_three ||
                item.video_four
              );

              return (
                <div key={index} className="post">
                  <div className="top_container">
                    <div className="profile_img">
                      <img
                        width="50px"
                        src={`${ApiBaseUrl}${item.client.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="name_date">
                      <p>{item.client.name}</p>
                      <p>{formatDate(item.created_at)}</p>
                    </div>
                    <div
                      className="action_btn"
                      style={{ visibility: "hidden" }}
                    >
                      <BsThreeDotsVertical />
                    </div>
                  </div>
                  {!item.poll_question ? (
                    <>
                      <div
                        id={`carouselExample${item.id}`}
                        className="post_main_image_container carousel slide"
                        style={{ display: allNull ? "none" : "flex" }}
                      >
                        <div
                          className="carousel-inner"
                          // style={{ width: "100%", height: "100%" }}
                        >
                          {item.image_one && (
                            <div
                              className="carousel-item active"
                              // style={{ width: "100%", height: "100%" }}
                            >
                              <img
                                src={`${ApiBaseUrl}${item.image_one}`}
                                className="d-block w-100"
                                alt="Post One"
                              />
                            </div>
                          )}

                          {item.image_two && (
                            <div className="carousel-item active">
                              <img
                                src={`${ApiBaseUrl}${item.image_two}`}
                                className="d-block w-100"
                                alt="Post Two"
                              />
                            </div>
                          )}

                          {item.image_three && (
                            <div className="carousel-item active">
                              <img
                                src={`${ApiBaseUrl}${item.image_three}`}
                                className="d-block w-100"
                                alt="Post Three"
                              />
                            </div>
                          )}

                          {item.image_four && (
                            <div className="carousel-item active">
                              <img
                                src={`${ApiBaseUrl}${item.image_four}`}
                                className="d-block w-100"
                                alt="Post Four"
                              />
                            </div>
                          )}

                          {item.video_one && (
                            <div className="carousel-item active">
                              <video controls>
                                <source
                                  src={`${ApiBaseUrl}${item.video_one}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}

                          {item.video_two && (
                            <div className="carousel-item active">
                              <video controls>
                                <source
                                  src={`${ApiBaseUrl}${item.video_two}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}

                          {item.video_three && (
                            <div className="carousel-item active">
                              <video controls>
                                <source
                                  src={`${ApiBaseUrl}${item.video_three}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}

                          {item.video_four && (
                            <div className="carousel-item active">
                              <video controls>
                                <source
                                  src={`${ApiBaseUrl}${item.video_four}`}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            </div>
                          )}
                        </div>
                        <button
                          className="carousel-control-prev carousel_button"
                          type="button"
                          data-bs-target={`#carouselExample${item.id}`}
                          data-bs-slide="prev"
                        >
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                        </button>
                        <button
                          className="carousel-control-next carousel_button"
                          type="button"
                          data-bs-target={`#carouselExample${item.id}`}
                          data-bs-slide="next"
                        >
                          <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                          ></span>
                          <span className="visually-hidden">Next</span>
                        </button>
                      </div>

                      <div
                        className="caption_container"
                        // style={{ maxHeight: allNull ? "525px" : "80px" }}
                      >
                        <p>
                          {!moreCaption
                            ? item.caption_description
                                .split(" ")
                                .slice(0, 30)
                                .join(" ")
                            : item.caption_description}{" "}
                          <span onClick={SeeMoreCaption}>
                            {item.caption_description.split(" ").length <= 30
                              ? null
                              : seeMoreState}
                          </span>
                        </p>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="caption_container">
                        <p>{item.poll_question}</p>
                      </div>
                      <div
                        className="options_container"
                        style={
                          answer.includes(item.id)
                            ? { display: "none" }
                            : { display: "flex" }
                        }
                      >
                        {item.option_one.name && (
                          <button
                            className="options"
                            onClick={() => OnVote(item.id, 1)}
                          >
                            {item.option_one.name}
                          </button>
                        )}
                        {item.option_two.name && (
                          <button
                            className="options"
                            onClick={() => OnVote(item.id, 2)}
                          >
                            {item.option_two.name}
                          </button>
                        )}
                        {item.option_three.name && (
                          <button
                            className="options"
                            onClick={() => OnVote(item.id, 3)}
                          >
                            {item.option_three.name}
                          </button>
                        )}
                        {item.option_four.name && (
                          <button
                            className="options"
                            onClick={() => OnVote(item.id, 4)}
                          >
                            {item.option_four.name}
                          </button>
                        )}
                      </div>
                      <div
                        className="answered_options_container"
                        style={
                          answer.includes(item.id)
                            ? { display: "flex" }
                            : { display: "none" }
                        }
                      >
                        {item.option_one.name && (
                          <div className="options">
                            <div className="text_opt_val">
                              <span>{item.option_one.name}</span>
                              <span>{`${Math.floor(
                                item.option_one.percentage
                              )}%`}</span>
                            </div>
                            <div
                              style={{
                                width: `${Math.floor(
                                  item.option_one.percentage
                                )}%`,
                              }}
                              className="coloured_highlight"
                            ></div>
                          </div>
                        )}
                        {item.option_two.name && (
                          <div className="options">
                            <div className="text_opt_val">
                              <span>{item.option_two.name}</span>
                              <span>{`${Math.floor(
                                item.option_two.percentage
                              )}%`}</span>
                            </div>
                            <div
                              style={{
                                width: `${Math.floor(
                                  item.option_two.percentage
                                )}%`,
                              }}
                              className="coloured_highlight"
                            ></div>
                          </div>
                        )}
                        {item.option_three.name && (
                          <div className="options">
                            <div className="text_opt_val">
                              <span>{item.option_three.name}</span>
                              <span>{`${Math.floor(
                                item.option_three.percentage
                              )}%`}</span>
                            </div>
                            <div
                              style={{
                                width: `${Math.floor(
                                  item.option_three.percentage
                                )}%`,
                              }}
                              className="coloured_highlight"
                            ></div>
                          </div>
                        )}
                        {item.option_four.name && (
                          <div className="options">
                            <div className="text_opt_val">
                              <span>{item.option_four.name}</span>
                              <span>{`${Math.floor(
                                item.option_four.percentage
                              )}%`}</span>
                            </div>
                            <div
                              style={{
                                width: `${Math.floor(
                                  item.option_four.percentage
                                )}%`,
                              }}
                              className="coloured_highlight"
                            ></div>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                  <div className="user_action_btns">
                    <Like PostData={item} GetLikeCount={GetAllPosts} />
                    <Comments PostData={item} />
                    {/* <div className="share">
                      <FaShare className="action_icon" />
                    </div> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        onHidden={resetModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content browse_file_modal">
            {loggedIn ? (
             <div className="modal-body">
             <div className="categoryheader">
               <p>
                 Upload in categories <span style={{ color: "red" }}>*</span>
               </p>
               <button
                 type="button"
                 data-bs-dismiss="modal"
                 aria-label="Close"
               >
                 <RxCross2 />
               </button>
             </div>
           
             {/* Category Selection */}
             <div className="category_container">
               {categories
               .filter(item => item.category_name !== "Poll")
               .map((item, index) => (
                 <div key={index}>
                   <input
                     type="radio"
                     name="category"
                     id={item.category_name}
                     value={item.id}
                     onChange={handleCategoryChange}
                   />
                   <label htmlFor={item.category_name} className="cat_btn">
                     <p>{item.category_name}</p>
                   </label>
                 </div>
               ))}
               {categoryError && (
                 <p style={{ color: "red", fontSize: "12px" }}>{categoryError}</p>
               )}
             </div>
           
             {/* Caption Input */}
             <div className="caption_container">
               <input
                 type="text"
                 value={caption}
                 onChange={handleCaptionChange}
                 placeholder="Type your caption..."
               />
               {captionError && (
                 <p style={{ color: "red", fontSize: "12px" }}>{captionError}</p>
               )}
             </div>
           
             {/* Image Input */}
             <div className="img_vid_input">
               <div className="image_input_wrapper">
                 <p>
                   Select images to post{" "}
                   <span className="text-warning">( select up to 4 max. )</span>
                 </p>
                 <input
                   type="file"
                   accept="image/*"
                   onChange={handleImageChange}
                   multiple
                 />
                 <div className="image-preview-wrapper">
                   {imagePreviews.map((preview, index) => (
                     <div
                       key={index}
                       style={{
                         position: "relative",
                         display: "inline-block",
                         margin: "5px",
                       }}
                     >
                       <img
                         src={preview}
                         alt={`Preview ${index + 1}`}
                         style={{
                           width: "100px",
                           height: "100px",
                           cursor: "pointer",
                         }}
                         onClick={() => openModal(preview)}
                       />
                       <button
                         onClick={() => handleDeleteImage(index)}
                         style={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                           backgroundColor: "red",
                           color: "white",
                           border: "none",
                           cursor: "pointer",
                           padding: "5px",
                           fontSize: "10px",
                         }}
                       >
                         X
                       </button>
                     </div>
                   ))}
                 </div>
           
                 {/* Image Modal */}
                 {isModalOpen && (
                   <div
                     className="modal-overlay"
                     onClick={closeModal}
                     style={{
                       position: "fixed",
                       top: 0,
                       left: 0,
                       width: "100%",
                       height: "100%",
                       backgroundColor: "rgba(0, 0, 0, 0.8)",
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       zIndex: 1000,
                     }}
                   >
                     <div
                       className="modal-content"
                       onClick={(e) => e.stopPropagation()}
                       style={{
                         position: "relative",
                         backgroundColor: "white",
                         padding: "20px",
                         borderRadius: "8px",
                         maxWidth: "90%",
                         maxHeight: "90%",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       <img
                         src={modalImage}
                         alt="Full-size preview"
                         style={{
                           maxWidth: "100%",
                           maxHeight: "100%",
                         }}
                       />
                       <button
                         onClick={closeModal}
                         style={{
                           position: "absolute",
                           top: "10px",
                           right: "10px",
                           backgroundColor: "red",
                           color: "white",
                           border: "none",
                           cursor: "pointer",
                           padding: "5px 10px",
                           borderRadius: "50%",
                           fontSize: "16px",
                         }}
                       >
                         X
                       </button>
                     </div>
                   </div>
                 )}
               </div>
           
               {/* Video Input */}
               <div className="video_input_wrapper">
                 <p>
                   Select videos to post{" "}
                   <span className="text-warning">( select upto 4 max. )</span>
                 </p>
                 <input
                   type="file"
                   accept="video/*"
                   onChange={handleVideoChange}
                   multiple
                 />
           
                 <div className="video-preview-wrapper">
                   {videoPreviews.map((preview, index) => (
                     <div
                       key={index}
                       style={{
                         position: "relative",
                         display: "inline-block",
                         margin: "5px",
                       }}
                     >
                       <img
                         src={preview}
                         alt={`Preview ${index + 1}`}
                         style={{
                           width: "100px",
                           height: "100px",
                           cursor: "pointer",
                         }}
                         onClick={() => openVideoModal(preview)}
                       />
                       <button
                         onClick={() => handleDeleteVideo(index)}
                         style={{
                           position: "absolute",
                           top: 0,
                           right: 0,
                           backgroundColor: "red",
                           color: "white",
                           border: "none",
                           cursor: "pointer",
                           padding: "5px",
                           fontSize: "10px",
                         }}
                       >
                         X
                       </button>
                     </div>
                   ))}
                 </div>
           
                 {/* Video Modal */}
                 {isVideoModalOpen && (
                   <div
                     className="modal-overlay"
                     onClick={closeVideoModal}
                     style={{
                       position: "fixed",
                       top: 0,
                       left: 0,
                       width: "100%",
                       height: "100%",
                       backgroundColor: "rgba(0, 0, 0, 0.8)",
                       display: "flex",
                       justifyContent: "center",
                       alignItems: "center",
                       zIndex: 1000,
                     }}
                   >
                     <div
                       className="modal-content"
                       onClick={(e) => e.stopPropagation()}
                       style={{
                         position: "relative",
                         backgroundColor: "white",
                         padding: "20px",
                         borderRadius: "8px",
                         maxWidth: "90%",
                         maxHeight: "90%",
                         display: "flex",
                         justifyContent: "center",
                         alignItems: "center",
                       }}
                     >
                       <img
                         src={modalVideo}
                         alt="Full-size preview"
                         style={{
                           maxWidth: "100%",
                           maxHeight: "100%",
                         }}
                       />
                       <button
                         onClick={closeVideoModal}
                         style={{
                           position: "absolute",
                           top: "10px",
                           right: "10px",
                           backgroundColor: "red",
                           color: "white",
                           border: "none",
                           cursor: "pointer",
                           padding: "5px 10px",
                           borderRadius: "50%",
                           fontSize: "16px",
                         }}
                       >
                         X
                       </button>
                     </div>
                   </div>
                 )}
               </div>
             </div>
           
             {/* Post Button */}
             <div className="action_btn_container">
               <button
                 className="action_btn"
                 data-bs-dismiss="modal"
                 style={{ visibility: "hidden" }}
               >
                 <img src="/image/communityIcons/photos.png" alt="" />
                 <p>+Add</p>
               </button>
               <button
                 className="action_btn"
                 onClick={AddPost}
               >
                 Post
               </button>
             </div>
           </div>
           
            ) : (
              <div className="not_logged_in_wrapper">
                <p className="not_logged_in_text">
                  <span className="heading">"Join Our Community Now!"</span>
                  <br />
                  Please log in to participate in our community. Your insights
                  and contributions are valued.
                </p>
                <div className="not_logged_in_btn_wrapper">
                  <button className="cancel" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="proceed_to_login"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log In
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content browse_file_modal">
            {loggedIn ? (
              <div className="modal-body">
                <div className="categoryheader">
                  <p>
                    Upload in categories<span style={{ color: "red" }}>*</span>
                  </p>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <RxCross2 />
                  </button>
                </div>
                <div className="category_container">
                  {categories.map((item, index) => (
                    <div key={index}>
                      <input
                        type="radio"
                        name="category"
                        id={`${item.category_name}poll`}
                        value={item.id}
                        onChange={handleCategoryChange}
                      />
                      <label
                        htmlFor={`${item.category_name}poll`}
                        className="cat_btn"
                      >
                        <p>{item.category_name}</p>
                      </label>
                    </div>
                  ))}
                </div>
                <div className="caption_container">
                  <input
                    type="text"
                    value={caption}
                    onChange={handleCaptionChange}
                    placeholder="Type your poll question"
                  />
                </div>
                <div className="options_container_to_create">
                  <div className="option_input">
                    <input
                      type="text"
                      placeholder="Option one"
                      value={options.option1}
                      onChange={(e) => handleChange(e, "option1")}
                      maxLength={15} 
                    />
                  </div>
                  {/* {options.option1 && ( */}
                  <div className="option_input">
                    <input
                      type="text"
                      placeholder="Option two"
                      value={options.option2}
                      onChange={(e) => handleChange(e, "option2")}
                      maxLength={15} 
                    />
                  </div>
                  {/* )} */}
                  {/* {options.option2 && ( */}
                  <div className="option_input" style={thirdOptionDisplay}>
                    <input
                      type="text"
                      placeholder="Option three"
                      value={options.option3}
                      onChange={(e) => handleChange(e, "option3")}
                      maxLength={15} 
                    />
                    <div
                      className="remove_poll_option"
                      onClick={RemoveThirdPollOption}
                      style={removeThirdOptionDisplay}
                    >
                      <RxCross2 className="remove_poll_option_icon" />
                    </div>
                  </div>
                  {/* )} */}
                  {/* {options.option3 && ( */}
                  <div className="option_input" style={fourthOptionDisplay}>
                    <input
                      type="text"
                      placeholder="Option four"
                      value={options.option4}
                      onChange={(e) => handleChange(e, "option4")}
                      maxLength={15} 
                    />
                    <div
                      className="remove_poll_option"
                      onClick={RemoveFourthPollOption}
                      style={removeFourthOptionDisplay}
                    >
                      <RxCross2 className="remove_poll_option_icon" />
                    </div>
                  </div>
                  <div
                    className="option_input"
                    style={addMoreOptionButtonDisplay}
                    onClick={AddMoreOption}
                  >
                  <input
                      readOnly
                      type="text"
                      placeholder="Add another option"
                  />
                  </div>
                  {/* )} */}
                </div>
                <div className="action_btn_container">
                  <button
                    className="action_btn"
                    data-bs-dismiss="modal"
                    style={{ visibility: "hidden" }}
                  >
                    <img src="/image/communityIcons/photos.png" alt="" />
                    <p>+Add</p>
                  </button>
                  <button
                    className="action_btn"
                    data-bs-dismiss="modal"
                    onClick={CreatePoll}
                  >
                    Add Poll
                  </button>
                </div>
              </div>
            ) : (
              <div className="not_logged_in_wrapper">
                <p className="not_logged_in_text">
                  <span className="heading">"Join Our Community Now!"</span>
                  <br />
                  Please log in to participate in our community. Your insights
                  and contributions are valued.
                </p>
                <div className="not_logged_in_btn_wrapper">
                  <button className="cancel" data-bs-dismiss="modal">
                    Cancel
                  </button>
                  <button
                    className="proceed_to_login"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      navigate("/login");
                    }}
                  >
                    Log In
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ComPosts;
