import React, { Suspense, lazy, useContext } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./modules/admin/AuthContext";
import { isMobile, isTablet } from "react-device-detect";

import HostDashboard from "./modules/hosting-module/host-dashboard/HostDashboard";
import Dashboard from "./modules/hosting-module/host-dashboard/dashboard/DashBoard";
import HostAvailbilty from "./modules/hosting-module/host-dashboard/availability/HostAvailability";
import HostBookings from "./modules/hosting-module/host-dashboard/host-bookings/HostBookings";
import CommunityHome from "./modules/community-module/CommunityHome";
import SelectPetToHost from "./modules/hosting-module/forms/booking-form/SelectPetToHost";
import OrderDetails from "./modules/hosting-module/forms/booking-form/OrderDetails";
import OrderSummary from "./modules/hosting-module/forms/booking-form/OrderSummary";
import FilteredHosts from "./modules/hosting-module/pages/home-page/sections/filtered-hosts/FilteredHosts";
import AllStarHosts from "./modules/hosting-module/pages/all-hosts/AllStarHosts";
import AllPopularHosts from "./modules/hosting-module/pages/all-hosts/AllPopularHosts";
import AllNearByHosts from "./modules/hosting-module/pages/all-hosts/AllNearByHosts";
import AllHostsByPrice from "./modules/hosting-module/pages/all-hosts/AllHostsByPrice";
import AppDownload from "./modules/hosting-module/components/app-download/AppDownload";
import Protected from "./Protected";
import FavHosts from "./modules/hosting-module/pages/fav-hosts/FavHosts";
import MyBookings from "./modules/hosting-module/pages/my-bookings/MyBookings";
import TermsConditions from "./modules/hosting-module/pages/terms-conditions/TermsConditions";
import PrivacyPolicy from "./modules/hosting-module/pages/privacy-policy/PrivacyPolicy";
import UpdatePet from "./modules/hosting-module/forms/update-pet-form/UpdatePet";
import Loading from "./modules/hosting-module/components/loading/Loading";
import PaymentSuccess from "./modules/hosting-module/pages/payment-status/PaymentSuccess";
import ClientProfile from "./modules/hosting-module/pages/profiles/client-profile/ClientProfile";

import CommunityGuidelines from "./modules/hosting-module/pages/footarPages/community/communityGuidelines";
import BecomeHost from "./modules/hosting-module/pages/footarPages/petHost/becomeHost";
import FaqForHost from "./modules/hosting-module/pages/footarPages/petHost/faqForHost";
import HostDedicatedSupportOld from "./modules/hosting-module/pages/footarPages/petHost/hostDedicatedSupport";
import HostDedicatedSupport from "./modules/hosting-module/pages/footer-pages/pet-host/HostDedicatedSupport";
import BecomeVerifiedHost from "./modules/hosting-module/pages/footarPages/petHost/becomeVerifiedHost";
import SafetyCarePetHosting from "./modules/hosting-module/pages/footarPages/petHost/safetyCarePetHosting";
import HostTermAndConditions from "./modules/hosting-module/pages/footarPages/petHost/hostTermAndConditions";
import PetHostingGuidelines from "./modules/hosting-module/pages/footarPages/petHost/petHostingGuidelines";
import FaqForParent from "./modules/hosting-module/pages/footarPages/petParent/faqForParent";
import HowWorkForParent from "./modules/hosting-module/pages/footarPages/petParent/howWorkForParent";
import PetCareTipsAdvice from "./modules/hosting-module/pages/footarPages/petParent/petCareTipsAdvice";
import PetParentSupport from "./modules/hosting-module/pages/footarPages/petParent/petParentSupport";
import PetParentPrivacyPolicy from "./modules/hosting-module/pages/footarPages/petParent/petParentPrivacyPolicy";
import PreparingPetBoarding from "./modules/hosting-module/pages/footarPages/petParent/preparingPetBoarding";
import AboutWoofily from "./modules/hosting-module/pages/footarPages/woofily/aboutWoofily";
import WoofilyCommitments from "./modules/hosting-module/pages/footer-pages/woofily/WoofilyCommitments";
import HowItWorksForPetParent from "./modules/hosting-module/pages/footer-pages/pet-parent/HowItWorksForPetParent";
import BecomeAPetHost from "./modules/hosting-module/pages/footer-pages/pet-host/BecomeAPetHost";


import FinalOrderSummary from "./modules/hosting-module/pages/order-details/final-order-summary";
import HostFinalOrderDetail from "./modules/hosting-module/host-dashboard/host-order-details/host-final-order-detail";
import CheckinForm from "./modules/hosting-module/host-dashboard/checkIn/checkinForm";
import CheckoutForm from "./modules/hosting-module/host-dashboard/checkOut/checkoutForm";
import Invoice from "./modules/invoice-module/invoice";
import ExtraTimeOrderSummary from "./modules/hosting-module/forms/extraTimeOrderSummary.jsx/extraTimeOrderSummary";
import GetDirections from "./modules/hosting-module/pages/order-details/getDirections";
import OurStory from "./modules/hosting-module/pages/footer-pages/woofily/OurStory";
import Register from "./authentication/signup/Register";
import RegisterationStepTwo from "./authentication/signup/RegistrationStepTwo";
import CancelledBooking from "./modules/hosting-module/host-dashboard/cancelledBooking/cancelledBooking";
import ReviewList from "./modules/hosting-module/host-dashboard/bookingReview/reviewList";
import EmailVerification from "./authentication/emailVerification/emailVerification";
import MobileUpdation from "./authentication/mobileUpdation/mobileUpdate";
import HostDocumentForm from "./modules/hosting-module/pages/hostDocument/hostDocumentForm";
import CrmDashboard from "./modules/hosting-module/components/admin-crm/crmDashboard";
import PetCareTipsAndAdvice from "./modules/hosting-module/pages/footer-pages/pet-parent/PetCareTipsAndAdvice";
import BecomeAVerifiedPetHost from "./modules/hosting-module/pages/footer-pages/pet-host/BecomeAVerifiedPetHost";
import ProfileHost from "./modules/hosting-module/pages/profiles/profileHost/profileHost";
import { ContactPage } from "@mui/icons-material";
import ContactUs from "./modules/hosting-module/pages/footer-pages/contact-us/contact-us";
import ScrollToTop from "./ScrollToTop";
import EnquiryForm from "./modules/hosting-module/forms/enquiryForm/enquiryForm";
const Home = lazy(() =>
  import("./modules/hosting-module/pages/home-page/Home")
);
const Login = lazy(() => import("./authentication/login/Login"));
const SignIn = lazy(() => import("./authentication/signin/Login"));
const HostProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/host-profile/HostProfile")
);
const PetProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/pet-profile/PetProfile")
);
const CompleteParentProfile = lazy(() =>
  import(
    "./modules/hosting-module/forms/complete-profile/CompleteParentProfile"
  )
);
const ParentProfile = lazy(() =>
  import("./modules/hosting-module/pages/profiles/parent-profile/ParentProfile")
);
const BecomeHostForm = lazy(() =>
  import("./modules/hosting-module/forms/become-host-form/BecomeHostForm")
);
const AllBlogs = lazy(() =>
  import("./modules/hosting-module/pages/blogs/AllBlogs")
);
const OneBlog = lazy(() =>
  import("./modules/hosting-module/pages/blogs/OneBlog")
);
const AddPet = lazy(() =>
  import("./modules/hosting-module/forms/add-pet-form/AddPet")
);

const AdminLogin = React.lazy(() => import("./modules/admin/admin-login"));
const AdminPanel = React.lazy(() => import("./modules/admin/main-panel"));
const CreateBlogBlog = React.lazy(() =>
  import("./modules/admin/createBlogMain")
);
const BlogBlogEdit = React.lazy(() => import("./modules/admin/editBlog"));
const SeoList = React.lazy(() => import("./modules/admin/seo-list"));
const PurposeSeo = React.lazy(() => import("./modules/admin/ManageSeo"));
const EditSeo = React.lazy(() => import("./modules/admin/edit-seo"));

const AppDownloadPage = () => {
  return <div>App Download Page</div>;
};

const isDevice = () => {
  return isMobile || isTablet || navigator.userAgent.match(/iPad/i);
};

function App() {
  const customToastStyle = {
    top: "30%",
    left: "75%",
    transform: "translate(-50%, -50%)",
  };

  const { isLoggedIn } = useContext(AuthContext);

  const isMobileOrTablet = isDevice();

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
      <ScrollToTop />
        <Routes>
          {/* If on mobile/tablet and not accessing the blog page, redirect to app download */}
          <Route
            path="/"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <Home />
            }
          />
          <Route
            path="/login"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <SignIn />
            }
          />
          <Route
            path="/sign-up"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <Register />
            }
          />
          <Route
            path="/user-details"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <RegisterationStepTwo />
              )
            }
          />
          <Route
            path="/host/:hostId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HostProfile />
              )
            }
          />
          <Route
            path="/host-profile/:hostId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <ProfileHost/>
              )
            }
          />
          <Route
            path="/pet/:petId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<PetProfile />} />
              )
            }
          />
          <Route
            path="/parent-profile"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<ParentProfile />} />
              )
            }
          />
          <Route
            path="/profile"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<ClientProfile />} />
              )
            }
          />

          <Route
            path="/favourite-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<FavHosts />} />
              )
            }
          />

          <Route
            path="/my-bookings"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<MyBookings />} />
              )
            }
          />

          <Route
            path="/update-profile/:id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<CompleteParentProfile />} />
              )
            }
          />

          <Route
            path="/update-pet-profile/:PetId"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<UpdatePet />} />
              )
            }
          />
          <Route
            path="/become-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<BecomeHostForm />} />
              )
            }
          />
          <Route
            path="/add-pet"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<AddPet />} />
              )
            }
          />

          {/* Blog routes accessible on all devices */}
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route
            path="/cancellation-and-refund-policy"
            element={<AllBlogs />}
          />
          <Route path="/blogs" element={<AllBlogs />} />
          <Route path="/blogs/:slug" element={<OneBlog />} />

          {/* All Hosts Routes */}
          <Route
            path="/all-star-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllStarHosts />
              )
            }
          />
          <Route
            path="/all-best-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllPopularHosts />
              )
            }
          />
          <Route
            path="/all-near-by-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllNearByHosts />
              )
            }
          />
          <Route
            path="/all-hosts-by-price"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AllHostsByPrice />
              )
            }
          />

          {/* Order Flow Routes */}
          <Route
            path="/select-pet-to-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<SelectPetToHost />} />
              )
            }
          />
          <Route
            path="/order-details"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<OrderDetails />} />
              )
            }
          />
          <Route
            path="/order-summary"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<OrderSummary />} />
              )
            }
          />
          <Route
            path="/extratimeordersummary"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<ExtraTimeOrderSummary />} />
              )
            }
          />
          <Route
            path="/payment-status"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<PaymentSuccess />} />
              )
            }
          />

          {/* Host Dashboard Routes */}
          <Route
            path="/host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<HostDashboard />} />
              )
            }
          >
            <Route index element={<Dashboard />} />
            <Route
              path="/host/host-dashboard"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<Dashboard />} />
                )
              }
            />
            <Route
              path="/host/host-availabilty"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<HostAvailbilty />} />
                )
              }
            />
            <Route
              path="/host/my-bookings"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<HostBookings />} />
                )
              }
            />
            <Route
              path="/host/cancelled-bookings"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<CancelledBooking />} />
                )
              }
            />
            <Route
              path="/host/review-list"
              element={
                isMobileOrTablet ? (
                  <Navigate to="/app-download" />
                ) : (
                  <Protected cmp={<ReviewList />} />
                )
              }
            />
          </Route>

          {/* Community Routes */}
          <Route
            path="/community"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CommunityHome />
              )
            }
          />
          <Route
            path="/filtered-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <FilteredHosts />
              )
            }
          />

          {/* Admin Panel Routes */}
          <Route
            path="/admin"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AdminLogin />
              )
            }
          />
          <Route
            path="/admin-panel"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : isLoggedIn ? (
                <AdminPanel />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            exact
            path="/blog-create"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CreateBlogBlog />
              )
            }
          />
          <Route
            exact
            path="/blog-edit/:blog_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <BlogBlogEdit />
              )
            }
          />
          <Route
            exact
            path="/seo-list"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <SeoList />
            }
          />
          <Route
            exact
            path="/seo-create"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PurposeSeo />
              )
            }
          />
          <Route
            exact
            path="/seo-edit/:slug_id"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <EditSeo />
            }
          />

          {/* ::::::::::::::::::::::::::::::::::::::::::::::: F O O T E R - P A G E S - R O U T E S - S T A R T - H E R E ::::::::::::::::::::::::::::::::::::::::::::::: */}

          {/* Woofily Section Routes Start Here */}
          <Route
            exact
            path="/contact-us"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <ContactUs />
            }
          />
          <Route
            exact
            path="/our-story"
            element={
              isMobileOrTablet ? <Navigate to="/app-download" /> : <OurStory />
            }
          />

          <Route
            exact
            path="/woofily-commitments"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <WoofilyCommitments />
              )
            }
          />

          {/* Woofily Section Routes End Here */}

          {/* Pet Host Section Routes Start Here */}
          <Route
            exact
            path="/become-a-pet-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <BecomeAPetHost />
              )
            }
          />
          <Route
            exact
            path="/become-a-verified-pet-host"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <BecomeAVerifiedPetHost />
              )
            }
          />
          <Route
            exact
            path="/host-dedicated-support"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HostDedicatedSupport />
              )
            }
          />
          <Route
            exact
            path="/pet-hosting-guidelines"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PetHostingGuidelines />
              )
            }
          />
          <Route
            exact
            path="/safety-and-care-tips-for-pet-hosts"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <SafetyCarePetHosting />
              )
            }
          />
          <Route
            exact
            path="/host-terms-and-conditions"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HostTermAndConditions />
              )
            }
          />
          <Route
            exact
            path="/Woofily-host-FAQs"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <FaqForHost />
              )
            }
          />
          {/* Pet Host Section Routes End Here */}

          {/* Pet Parent Section Routes Start Here */}
          {/* Pet Host Section Routes End Here */}

          {/* Pet Parent Section Routes Start Here */}
          <Route
            exact
            path="/how-it-works-for-pet-parent"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HowItWorksForPetParent />
              )
            }
          />
          <Route
            exact
            path="/pet-parent-support"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PetParentSupport />
              )
            }
          />
          <Route
            exact
            path="/preparing-your-pet-for-boarding"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PreparingPetBoarding />
              )
            }
          />
          {/* <Route
            exact
            path="/pet-care-tips-and-advice"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PetCareTipsAdvice />
              )
            }
          /> */}
          <Route
            exact
            path="/pet-care-tips-and-advice"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PetCareTipsAndAdvice />
              )
            }
          />
          <Route
            exact
            path="/Woofily-parent-FAQs"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <FaqForParent />
              )
            }
          />
          <Route
            exact
            path="/pet-parent-privacy-policy"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <PetParentPrivacyPolicy />
              )
            }
          />

          {/* Pet Parent Section Routes End Here */}

          {/* Pet Community Section Routes Start Here */}

          {/* Pet Parent Section Routes End Here */}

          {/* Pet Community Section Routes Start Here */}
          <Route
            exact
            path="/join-the-Woofily-Host-Community"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CommunityGuidelines />
              )
            }
          />

          {/* Pet Community Section Routes End Here */}


          {/* Pet Community Section Routes End Here */}

          <Route
            exact
            path="/about-woofily"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <AboutWoofily />
              )
            }
          />

          {/* ::::::::::::::::::::::::::::::::::::::::::::::: F O O T E R - P A G E S - R O U T E S - E N D - H E R E ::::::::::::::::::::::::::::::::::::::::::::::: */}

          {/* App Download Page */}
          <Route path="/app-download" element={<AppDownload />} />

          {/* Redirect any unmatched routes to app download page on mobile/tablet */}
          <Route
            path="*"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          {/* order process */}
          <Route
            path="/final-order-summary/:order_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<FinalOrderSummary />} />
              )
            }
          />
          <Route
            path="/host-final-order-summary/:order_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<HostFinalOrderDetail />} />
              )
            }
          />
          <Route
            path="/order-checkin/:order_id/:parent_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<CheckinForm />} />
              )
            }
          />
          <Route
            path="/order-checkout/:order_id/:parent_id"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <Protected cmp={<CheckoutForm />} />
              )
            }
          />
          <Route
            exact
            path="/get-directions"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <GetDirections />
              )
            }
          />
          <Route
            exact
            path="/email-verification"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <EmailVerification />
              )
            }
          />
            <Route
            exact
            path="/mobile-updation"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <MobileUpdation />
              )
            }
          />
          <Route
            path="/host-document"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <HostDocumentForm />
              )
            }
          />
           <Route
            path="/crm-dashboard"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <CrmDashboard />
              )
            }
          />
          <Route
            path="/enquiry-form"
            element={
              isMobileOrTablet ? (
                <Navigate to="/app-download" />
              ) : (
                <EnquiryForm />
              )
            }
          />
        </Routes>
      </Suspense>
      <ToastContainer position="top-right" style={customToastStyle} />
    </BrowserRouter>
  );
}

export default App;
