import React, { useState, useEffect } from "react";
import "./hostDocumentForm.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { toast } from "react-toastify";
import { ApiBaseUrl } from "../../../../BaseUrl";


const HostDocumentForm = () => {
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const PARENT_ID = localStorage.getItem("PARENT_ID");
  const [activeTab, setActiveTab] = useState(0);
  const [businessData, setBusinessData] = useState({
    gstNumber: "",
    pancardNumber: "",
    aadharcard: "",
    otp: "",
  });
  const [step, setStep] = useState(1);
  const [stepIndividual, setStepIndividual] = useState(1);
  const [kycStatus, setKycStatus] = useState(null); 
  const [panStatus,setPanStatus]=useState(null);
  const [aadharStatus,setAadharStatus]=useState(null);
  const [gstStatus,setGstStatus]=useState(null);
  const checkKYCStatus = async () => {
    try {
      const response = await fetch(`${ApiBaseUrl}/user-kyc-status/${PARENT_ID}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const data = await response.json();
      setKycStatus(data[0].status);
      setAadharStatus(data[0].is_aadhar_verifiy);
      setPanStatus(data[0].is_pan_verifiy)
      setGstStatus(data[0].is_gst_verifiy)
    } catch (error) {
      console.error("Error fetching KYC status:", error);
    }
  };

  useEffect(() => {
    checkKYCStatus();
  }, []);
  const handleTabChange = (newValue) => setActiveTab(newValue);
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBusinessData((prev) => ({ ...prev, [name]: value }));
  };

  const verifyGST = async () => {
    try {
      const response = await fetch(ApiBaseUrl+"/gst-verifiy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ gst_number: businessData.gstNumber, user_id: PARENT_ID }),
      });
      const data = await response.json();
      if (data.status === true) {
        toast.success(data.message); 
        setStep(2);
      } else {
        toast.error(data.message || "Invalid GST Number. Please try again.");
      }
    } catch (error) {
      console.error("Error during GST verification:", error);
      toast.error("An error occurred while verifying GST. Please try again.");
    }
  };

  const verifyPAN = async () => {
    const response = await fetch(ApiBaseUrl+"/pan-card-verifiy", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` },
      body: JSON.stringify({ pan_card: businessData.pancardNumber, user_id: PARENT_ID }),
    });
    const data = await response.json();
    if (data.status === true) {
      toast.success(data.message); 
      setStep(3);
      setStepIndividual(2);
    } else {
      toast.error(data.message || "Invalid PAN Card Number. Please try again.");
    }
  };

  const sendAadhaarOTP = async () => {
    try {
      const response = await fetch(ApiBaseUrl+"/aadhar-send-otp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ aadhar_card: businessData.aadharcard }),
      });

      const data = await response.json();
      if (data.status === true) {
        toast.success(data.message); 
        localStorage.setItem("aadhar_client_id", data.data.client_id);
      } else {
        toast.error(data.message || "Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during Aadhaar OTP sending:", error);
      toast.error("An error occurred while sending OTP. Please try again.");
    }
  };

  const verifyAadhaarOTP = async () => {
    try {
      const clientId = localStorage.getItem("aadhar_client_id"); 
      const response = await fetch(ApiBaseUrl+"/aadhar-otp-verifiy", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          aadhar_number: businessData.aadharcard,
          otp: businessData.otp,
          user_id: PARENT_ID,
          aadhar_client_id: clientId,
        }),
      });
      const data = await response.json();
      if (data.status === true) {
        toast.success(data.message); 
        window.location.reload()
      } else {
        toast.error(data.message || "Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error during Aadhaar OTP verification:", error);
      toast.error("An error occurred while verifying OTP. Please try again.");
    }
  };



  return (
    <div className="host-document-container">
      <Header />
      <div className="content">
        {/* Show form if KYC status is not 1, else show success message */}
        {aadharStatus === true  &&  panStatus=== true || aadharStatus === true  &&  panStatus=== true && gstStatus===true  ? 
        (
          <div className="verified-message">
            <h4>Almost there! We are verifying your document details for a seamless and secure pet experience</h4>
          </div>
        )
        :
        (
          <>
            <div className="tabs-container">
              <button
                className={`tab-button ${activeTab === 0 ? "active" : ""}`}
                onClick={() => handleTabChange(0)}
              >
                Individual
              </button>
              <button
                className={`tab-button ${activeTab === 1 ? "active" : ""}`}
                onClick={() => handleTabChange(1)}
              >
                Business
              </button>
            </div>
            {activeTab === 0 && (
              <form className="form">
                  {stepIndividual === 1 && (
                  <div>
                    <label>
                      PAN Card
                      <input
                        type="text"
                        name="pancardNumber"
                        value={businessData.pancardNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={verifyPAN}>
                      Verify PAN
                    </button>
                  </div>
                )}
                {stepIndividual === 2 && (
                  <div>
                    <label>
                      Aadhaar Card
                      <input
                        type="text"
                        name="aadharcard"
                        value={businessData.aadharcard}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={sendAadhaarOTP}>
                      Send OTP
                    </button>
                    <label>
                      OTP
                      <input
                        type="text"
                        name="otp"
                        value={businessData.otp}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={verifyAadhaarOTP}>
                      Verify OTP
                    </button>
                  </div>
                )}

                </form>
            )}
            {activeTab === 1 && (
              <form className="form">
                {step === 1 && (
                  <div>
                    <label>
                      GST Number
                      <input
                        type="text"
                        name="gstNumber"
                        value={businessData.gstNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={verifyGST}>
                      Verify GST
                    </button>
                  </div>
                )}

                {step === 2 && (
                  <div>
                    <label>
                      PAN Card
                      <input
                        type="text"
                        name="pancardNumber"
                        value={businessData.pancardNumber}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={verifyPAN}>
                      Verify PAN
                    </button>
                  </div>
                )}

                {step === 3 && (
                  <div>
                    <label>
                      Aadhaar Card
                      <input
                        type="text"
                        name="aadharcard"
                        value={businessData.aadharcard}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={sendAadhaarOTP}>
                      Send OTP
                    </button>
                    <label>
                      OTP
                      <input
                        type="text"
                        name="otp"
                        value={businessData.otp}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="button" onClick={verifyAadhaarOTP}>
                      Verify OTP
                    </button>
                  </div>
                )}
              </form>
            )}
          </>
        ) }
      </div>
      <Footer />
    </div>
  );
};

export default HostDocumentForm;

