import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
const firebaseConfig = {
  apiKey: "AIzaSyDJGLGxexcD3odkzqGo-2KA59Y94fgn0lY",
  authDomain: "woofily-984fa.firebaseapp.com",
  projectId: "woofily-984fa",
  storageBucket: "woofily-984fa.firebasestorage.app",
  messagingSenderId: "498529077973",
  appId: "1:498529077973:web:d51979ecc330146743705c",
  measurementId: "G-79TRL53DX3"
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging };