import React from 'react'
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import "./contact-us.scss";
import { FaPhoneAlt, FaEnvelope } from "react-icons/fa";

function ContactUs() {
  return (
    <>
      <Header/>
        <section className="our-story-section-wrapper">
            <div className="first-section-wrapper">
            <div className="contact-details-wrapper">
                <h2 className="contact-heading">Get In Touch</h2>
                <div className="contact-detail">
                <FaPhoneAlt className="contact-icon" />
                <span className="contact-info">Call Us at    <a href="tel:+919711598828">+91 9711598828</a></span>
                </div>
                <div className="contact-detail">
                <FaEnvelope className="contact-icon" />
                <span className="contact-info">Email Us at    <a href="mailto:care@woofily.com">care@woofily.com</a></span>
                </div>
            </div>
            </div>
            
        </section>

      <Footer/>
    </>
  )
}

export default ContactUs
