import React, { useState } from 'react';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import './enquiryForm.scss'; // Import the SCSS file
import { ApiBaseUrl } from '../../../../BaseUrl';
import {  toast } from "react-toastify";
const EnquiryForm = () => {
  const USER_NAME = localStorage.getItem("USER_NAME") || '';
  const USER_EMAIL = localStorage.getItem("USER_EMAIL") || '';

  const [formData, setFormData] = useState({
    name: USER_NAME,
    email: USER_EMAIL,
    phone: '',
    type: '',
    description: '',
    attachment: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      attachment: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = new FormData();
    form.append('name', formData.name);
    form.append('email', formData.email);
    form.append('phone', formData.phone);
    form.append('type', formData.type);
    form.append('description', formData.description);
    if (formData.attachment) {
      form.append('attachment', formData.attachment);
    }

    try {
      const response = await fetch(`${ApiBaseUrl}/user-enquiry`, {
        method: 'POST',
        body: form,
      });

      if (response.ok) {
        const data = await response.json();
        toast.success(data.message);
        
      } else {
        const data = await response.json();
        toast.error(data.message)
      }
    } catch (error) {
      console.error('Error submitting the form', error);
    }
  };

  return (
    <>
      <Header />
      <div style={{ marginTop: '93px' }}>
      <div className="enquiry-form-container">
        <h2>Enquiry Form</h2>
        <form onSubmit={handleSubmit} className="enquiry-form">
          <div className="form-group">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone No:</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="type">Type:</label>
            <select
              id="type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              required
            >
              <option value="">Select</option>
              <option value="Payment Not Processed">Payment Not Processed</option>
              <option value="Billing Inquiry">Billing Inquiry</option>
              <option value="Update Payment Method">Update Payment Method</option>
              <option value="Booking Confirmation">Booking Confirmation</option>
              <option value="Booking Cancellation">Booking Cancellation</option>
              <option value="Reschedule Booking">Reschedule Booking</option>
              <option value="Host Not Found">Host Not Found</option>
              <option value="Payment Receipt">Payment Receipt</option>
              <option value="Host Documentation">Host Documentation</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="attachment">Attachment:</label>
            <input
              type="file"
              id="attachment"
              name="attachment"
              onChange={handleFileChange}
            />
          </div>
          <div className="form-group">
            <button type="submit" className="submit-button">
              Submit
            </button>
          </div>
        </form>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default EnquiryForm;
