import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';

import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from './modules/admin/AuthContext';
import { store } from './redux/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <AuthProvider>
    <App />
    </AuthProvider>
  </Provider>,
);
